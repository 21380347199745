import { div, e, t } from "../../tags";
import { font } from "../../font.module.css";

const namePattern = "^\\D+$";
function name() {
  return div({}, [
    e("label", { for: "name" }, [t("Qual seu nome? *")]),
    e("input", {
      type: "text",
      required: "true",
      placeholder: "nome",
      name: "name",
      class: font,
      minlength: "2",
      pattern: namePattern,
      autocomplete: "given-name",
    }),
    e("input", {
      type: "text",
      required: "true",
      placeholder: "sobrenome",
      name: "surname",
      class: font,
      minlength: "2",
      pattern: namePattern,
      autocomplete: "family-name",
    }),
  ]);
}

export default name;
