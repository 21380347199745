// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Y_3OUT9SeOKta0CPwuVVt {\n  color: #dc3545;\n}\n", ""]);
// Exports
exports.locals = {
	"error": "_1Y_3OUT9SeOKta0CPwuVVt"
};
module.exports = exports;
