import { div, e, t } from "../../tags";
import { font } from "../../font.module.css";
import config from "../../../config";

function select() {
  const select = e(
    "select",
    {
      required: "true",
      name: "company",
      class: font,
      autocomplete: "organization",
    },
    [
      // https://stackoverflow.com/a/8442831/1167210
      e(
        "option",
        { value: "", disabled: "true", selected: "true", hidden: "true" },
        [t("")]
      ),
      ...config
        .get("companies")
        .map((company) =>
          e("option", { value: JSON.stringify(company) }, [t(company.name)])
        ),
    ]
  );

  return select;
}

function company() {
  if (config.get("companies").length) {
    return div({}, [
      e("label", { for: "company" }, [t("Qual sua empresa? *")]),
      select(),
    ]);
  }
  return e("span");
}

export default company;
