// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16CMiAJ8W0GhpPXhOq4KDf {\n  flex-grow: 1;\n}\n\n._16CMiAJ8W0GhpPXhOq4KDf > input {\n  flex-grow: 0;\n  margin-right: 1rem;\n  align-self: baseline;\n}\n\n._16CMiAJ8W0GhpPXhOq4KDf > label {\n  flex-basis: unset;\n}\n\n._2OdZyiMqU-1rwv_usUJgF3 {\n  text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"eula": "_16CMiAJ8W0GhpPXhOq4KDf",
	"link": "_2OdZyiMqU-1rwv_usUJgF3"
};
module.exports = exports;
