// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,600&display=swap);"]);
// Module
exports.push([module.id, "._35vcVz_k6SkQES_m2CxUb6 {\n  font-family: \"Raleway\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto,\n    \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\",\n    \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n", ""]);
// Exports
exports.locals = {
	"font": "_35vcVz_k6SkQES_m2CxUb6"
};
module.exports = exports;
