// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2uLZq9fN3Xuzm7NJg0jBa1 {\n  font-size: 0.875rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  background-color: #e6e6e6;\n  color: rgba(0, 0, 0, 0.8);\n  border-radius: 0.25rem;\n  border-style: none;\n  border-width: 0;\n  cursor: pointer;\n  -webkit-appearance: button;\n  text-transform: none;\n  overflow: visible;\n  line-height: 1.15;\n  margin-left: 1em;\n  will-change: transform;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-backface-visibility: hidden;\n  backface-visibility: hidden;\n  -webkit-transform: translateZ(0);\n  transform: translateZ(0);\n  transition: -webkit-transform 0.25s ease-out;\n  transition: transform 0.25s ease-out;\n}\n\n._2uLZq9fN3Xuzm7NJg0jBa1:focus,\n._2uLZq9fN3Xuzm7NJg0jBa1:hover {\n  -webkit-transform: scale(1.05);\n  transform: scale(1.05);\n}\n\n._2bEweCn17a8ZiZ5omiI2mq {\n  background-color: #6fcf97;\n  color: #fff;\n  font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"btn": "_2uLZq9fN3Xuzm7NJg0jBa1",
	"btnPrimary": "_2bEweCn17a8ZiZ5omiI2mq"
};
module.exports = exports;
