import differenceInYears from "date-fns/differenceInYears";
import parseISO from "date-fns/parseISO";

import config from "./config";
import firebase from "./firebase";
import LOG from "./log";

import errorHandler from "./errorHandler";

/**
 * Modified from
 * https://developers.intercom.com/installing-intercom/docs/basic-javascript#section-basic-javascript
 */
/* eslint-disable prefer-rest-params */
function add() {
  const APP_ID = config.get("intercomAppId");
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${APP_ID}`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}
/* eslint-enable prefer-rest-params */

function boot() {
  window.setTimeout(() => {
    window.Intercom("boot", {
      app_id: config.get("intercomAppId"),
      created_at: Date.now() / 1000,
      // customer is cleared on unload; force modal
      // flow so everyone is properly registered on intercom
      hide_default_launcher: true,
    });
  }, 2 * 1000);
  window.addEventListener(
    "beforeunload",
    () => {
      window.Intercom("shutdown");
    },
    false
  );
}

async function open() {
  const user_id = await firebase.getFhirId();
  if (!user_id) {
    errorHandler.report(new Error("Can't get userId from token"));
    LOG("Something went wrong, can't get userId from token");
    return;
  }
  LOG("You are user", user_id);
  window.Intercom("update", {
    user_id,
    email: firebase.getEmail(),
    campaign: campaign(),
    "Sintomas para COVID": null,
    "Tipo atendimento PBH": null,
    "Data inicial a ser periciada": null,
    "Data final a ser periciada": null,
    "Contato COVID positivo": null,
    grupo_de_risco: null,
    intencao_inicial: null,
    necessidade: null,
    "Descreva em poucas palavras os seus sintomas": null,
    "Data inicial dos sintomas": null,
    "Informe qual queixa ou problema motivou a busca da primeira consulta realizada": null,
    "Descreva em poucas palavras o que te faz buscar uma segunda opinião": null,
    documentacao_disponivel: null,
    documento_enviado: null,
    "Nos conte sua dúvida ou preocupação": null,
    documentacao_propria: null,
    informacao_medicamento: null,
    "Conte pra mim os nomes dos medicamentos": null,
    "Informe qual queixa, problema ou situação motivou o pedido deste exame": null,
    objetivo_consulta: null,
    "Informe o peso que você deseja atingir com o tratamento": null,
    "Informe qual peso você manteve pelo maior período de tempo no último ano": null,
    alergia_alimentar: null,
    "Informe suas alergias alimentares": null,
    exames_lab_recentes: null,
    "Letra inicial do primeiro CID do seu atestado": null,
    "Informe a letra inicial do primeiro CID do seu atestado": null,
    "Informe os números do primeiro CID do seu atestado": null,
    "Letra inicial do segundo CID do seu atestado": null,
    "Informe a letra inicial do segundo CID do seu atestado": null,
    "Informe os números do segundo CID do seu atestado": null,
    "Letra inicial do terceiro CID do seu atestado": null,
    "Informe a letra inicial do terceiro CID do seu atestado": null,
    "Informe os números do terceiro CID do seu atestado": null,
    "Letra inicial do quarto CID do seu atestado": null,
    "Informe a letra inicial do quarto CID do seu atestado": null,
    "Informe os números do quarto CID do seu atestado": null,
    "Letra inicial do quinto CID do seu atestado": null,
    "Informe a letra inicial do quinto CID do seu atestado": null,
    "Informe os números do quinto CID do seu atestado": null,
    "Informe os números do CRM do médico": null,
    "Informe o estado do CRM do médico": null,
    "Informe a quantidade de dias de afastamento do seu atestado": null,
    "Informe o dia de emissão do seu atestado": null,
    "Informe o mês de emissão do seu atestado": null,
    "Informe o ano de emissão do seu atestado": null,
    "Informe o nome completo do colaborador": null,
    "Informe o CPF do colaborador": null,
    "Identificação do usuário": null,
  });
  window.setTimeout(() => {
    window.Intercom("showNewMessage");
  }, 300);
}

async function update(
  user_id,
  { name, first_name, cpf, company, phone, birthday, eula, genderType },
  { cep, estado, cidade }
) {
  const comp = company ? JSON.parse(company) : {};
  const age = differenceInYears(new Date(), parseISO(birthday));
  window.Intercom("update", {
    user_id,
    email: firebase.getEmail(),
    cpf,
    name,
    "first name": first_name,
    phone,
    cep,
    termos_e_condicoes: eula,
    "Cidade e Estado": `${cidade || ""}, ${estado || ""}`,
    "Data de Nascimento": birthday,
    company: {
      company_id: comp.id,
      name: comp.name,
    },
    campaign: campaign(),
    idade: age,
    sexo: genderType === "male" ? "masculino" : "feminino",
    // repeated because of PMF
    cidade,
    estado,
    data_de_nascimento: birthday,
  });
  window.setTimeout(() => {
    window.Intercom("showNewMessage");
  }, 300);
}

function campaign(campaignName) {
  if (campaignName != null) {
    sessionStorage.setItem("e778-covid-campaign", campaignName);
  }
  return sessionStorage.getItem("e778-covid-campaign");
}

export default {
  add,
  boot,
  open,
  update,
  campaign,
};
