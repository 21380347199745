import { AsYouType } from "libphonenumber-js/core";

import metadata from "./phone.metadata.json";
import { div, e, t } from "../../tags";
import { font } from "../../font.module.css";

const asYouType = new AsYouType("BR", metadata);
function validator(input) {
  asYouType.reset();
  input.value = asYouType.input(input.value);

  const phoneNumber = asYouType.getNumber();
  if (!phoneNumber || !phoneNumber.isValid()) {
    input.setCustomValidity("Este número parece ser inválido");
  } else {
    input.setCustomValidity("");
  }
}

function phone() {
  const input = e("input", {
    type: "tel",
    required: "true",
    placeholder: "(ddd) celular",
    name: "phone",
    class: font,
    minlength: "10",
    maxlength: "17",
    inputmode: "tel",
    autocomplete: "tel-national",
  });

  input.addEventListener("input", validator.bind(null, input), false);

  return div({}, [
    e("label", { for: "phone" }, [t("Qual seu celular? *")]),
    input,
  ]);
}

export default phone;
