import api from "../../api";
import intercom from "../../intercom";
import modal from "../index";

export async function onSubmit(patient) {
  // start early and run concurrently
  const cep = api.cepDetails(patient.cep);

  const registered = await api.register(patient);

  await intercom.update(registered.data.id, patient, await cep);
  modal.hide({ hideForm: true });
}
