import api from "./api";
import LOG from "./log";

let data = null;

const isTestUrl = () => {
  const params = new URLSearchParams(document.location.search.substring(1));
  return params.has("e778dev");
};

async function load(uid) {
  if (!uid) {
    const isTestEnv = process.env.NODE_ENV === "development" || isTestUrl();
    return load(isTestEnv ? "test" : "default");
  }
  LOG("fetching config", uid);
  try {
    data = await api.loadConfig(uid);
  } catch (e) {
    LOG("could not fetch config", e);
  }
}

function get(key) {
  if (!data) {
    LOG("no config loaded, cannot get key", key);
    return null;
  }
  return data[key];
}

export default {
  load,
  // export as function or webpack strips it out?
  data: () => (data ? { ...data } : null),
  get,
};
