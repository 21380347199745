import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import firebase from "./firebase";
import intercom from "./intercom";
import modal from "./modal";
import conf from "./config";
import LOG from "./log";
import "./errorHandler";

let campaignParam = "";

function open() {
  LOG(`opening '${campaignParam}'`);
  intercom.campaign(campaignParam);
  modal.show();
}

export async function init(config) {
  await conf.load(config);
  await firebase.init();
  intercom.add();
  intercom.boot();
  modal.init();

  const urlParams = new URLSearchParams(document.location.search);
  if (urlParams.get("e778shown") === "true") {
    open();
  }
}

/**
 * Attaches an onClick listener to a DOM Element
 * @param EventTarget
 * @param campaign flag sent to Intercom that helps alternate flows
 */
export function attach(element, campaign) {
  const urlParams = new URLSearchParams(document.location.search);
  campaignParam = campaign || urlParams.get("campaign") || "";
  LOG(`attaching to`, element);
  element.addEventListener("click", open, false);
}
