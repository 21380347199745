import { div, e, t } from "../../tags";
import { font } from "../../font.module.css";

// avoids escaping [.-] because of https://bugzilla.mozilla.org/show_bug.cgi?id=1227906
const pattern = "^\\d{5}[-]?\\d{3}$";

function cep() {
  const input = e("input", {
    type: "text",
    placeholder: "CEP da sua localidade",
    name: "cep",
    class: font,
    minlength: "8",
    maxlength: "9",
    inputmode: "decimal",
    autocomplete: "postal-code",
    required: "true",
    pattern,
  });

  return div({}, [e("label", { for: "cep" }, [t("Qual seu CEP? *")]), input]);
}

export default cep;
