import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";

import config from "./config";
import firebase from "./firebase";
import LOG from "./log";

import errorHandler from "./errorHandler";

const reportExceptionsWrapper = (fn) => async (...args) => {
  try {
    return await fn(...args);
  } catch (e) {
    errorHandler.report(e);
    throw e;
  }
};

async function register({
  first_name,
  surname,
  cep,
  cpf,
  company,
  phone,
  genderType,
  birthday,
}) {
  const url = `${config.get("backend")}/profile`;
  const user = firebase.user();
  const comp = company ? JSON.parse(company) : {};
  const birth = formatISO(parseISO(birthday), { representation: "date" });
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${await user.getIdToken()}`,
    },
    body: JSON.stringify({
      first_name,
      surname,
      cep,
      cpf,
      phone_number: phone,
      organization_name: comp.name,
      organization_id: comp.id,
      genderType,
      birth,
    }),
  });
  if (!response.ok) {
    throw new Error(`${response.status} ${await response.text()}`);
  }
  return response.json();
}

async function loadConfig(uid) {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "/config"
      : process.env.CONFIG_DIR_URL;
  const response = await fetch(`${baseUrl}/${uid}.json`);
  if (!response.ok) {
    throw new Error(JSON.stringify(await response.json()));
  }
  return response.json();
}

async function cepDetails(cep) {
  if (cep) {
    try {
      const response = await fetch(`${process.env.CEP_API_URL}/${cep}`);
      if (!response.ok) {
        throw new Error(`${response.status} ${await response.text()}`);
      }
      return response.json();
    } catch (e) {
      errorHandler.report(e);
      LOG(JSON.stringify(e));
    }
  }
  return { cep };
}

export default {
  register: reportExceptionsWrapper(register),
  loadConfig: reportExceptionsWrapper(loadConfig),
  cepDetails,
};
