// https://micromodal.now.sh/

import MicroModal from "micromodal";

import { e, div, t, logo } from "./tags";
import form, { onClose } from "./form";
import validation from "./validation";

import styles from "./styles.module.css";
import { font } from "./font.module.css";

import config from "../config";
import firebase from "../firebase";
import intercom from "../intercom";

const id = "e778-agatha-modal";

let formElement;
const firebaseElement = div({ id: `${id}-firebaseui` });
const validationElement = validation();

function init() {
  // depends on config
  formElement = form();

  const root = div(
    { id, "aria-hidden": "true", class: `${font} ${styles.slide}` },
    [
      div(
        {
          tabindex: "-1",
          "data-micromodal-close": "true",
          class: styles.overlay,
        },
        [
          div(
            {
              role: "dialog",
              "aria-modal": "true",
              "aria-labelledby": `${id}-title`,
              class: styles.container,
            },
            [
              e("header", { class: styles.header }, [
                logo(),
                e("h2", { id: `${id}-title`, class: styles.title }, [
                  t(config.get("fields.form.title") || "Login e Cadastro"),
                ]),
                e("button", {
                  "aria-label": "Fechar",
                  "data-micromodal-close": "true",
                  class: styles.close,
                }),
              ]),
              e(
                "main",
                {
                  id: "e778-covid-modal-content",
                  class: `${styles.content} ${font}`,
                },
                [firebaseElement, validationElement, formElement]
              ),
            ]
          ),
        ]
      ),
    ]
  );
  document.body.appendChild(root);
  firebase.start(`${id}-firebaseui`, onLogin);
}

async function onLogin() {
  const user = firebase.user();
  firebaseElement.hidden = true;
  validationElement.hidden = user.emailVerified;

  const isRegistered = await firebase.isRegisteredInDb();
  formElement.hidden = !user.emailVerified || isRegistered;

  if (isRegistered) {
    // short circuit to intercom
    await intercom.open();
    hide();
  }
}

async function show() {
  // short circuit to intercom
  // if user closes modal after login
  const user = firebase.user();
  if (user) {
    const isRegistered = await firebase.isRegisteredInDb();
    if (isRegistered) {
      await intercom.open();
      return;
    }
  }

  MicroModal.show(id, {
    awaitCloseAnimation: true,
  });
}

function hide({ hideForm = false } = {}) {
  if (hideForm) {
    formElement.hidden = hideForm;
  }
  MicroModal.close(id);
  onClose();
}

export default {
  init,
  show,
  hide,
};
