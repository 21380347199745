import { div, e, t } from "../../tags";

import styles from "./eula.module.css";

function eula() {
  return div(
    {
      class: styles.eula,
    },
    [
      e("input", {
        type: "checkbox",
        required: "true",
        name: "eula",
        id: "eula",
        title: "Leia e aceite os termos para continuar",
      }),
      e("label", { for: "eula" }, [
        t("Aceito os "),
        e(
          "a",
          {
            href: "https://bot.3778.care/eula.html",
            target: "_blank",
            class: styles.link,
          },
          [t("Termos de Uso e Política de Privacidade")]
        ),
        t(" *"),
      ]),
    ]
  );
}

export default eula;
