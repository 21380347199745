import { div, e, t } from "../../tags";
import { font } from "../../font.module.css";

const today = new Date().toISOString().split("T")[0];

function birthday() {
  const input = e("input", {
    type: "date",
    // fallback to type text
    placeholder: "aaaa-mm-dd",
    required: "true",
    name: "birthday",
    class: font,
    min: "1900-01-01",
    max: today,
    autocomplete: "bday",
  });

  return div({}, [
    e("label", { for: "birthday" }, [t("Qual sua data de nascimento? *")]),
    input,
  ]);
}

export default birthday;
