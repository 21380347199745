// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2iRCsy8J6JjywmaXT7WBwe {\n  display: flex;\n  justify-content: flex-end;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_2iRCsy8J6JjywmaXT7WBwe"
};
module.exports = exports;
