import { e, t } from "../../tags";
import styles from "./error.module.css";

function error() {
  return e("span", { hidden: true, class: styles.error }, [
    t("Este CPF já está cadastrado"),
  ]);
}

export default error;
