import { div, e, t } from "../../tags";
import styles from "./index.module.css";

import createLoading from "./loading";
import createSubmit from "./button";
import createError from "./error";
import name from "./name";
import gender from "./gender";
import phone from "./phone";
import cpf from "./cpf";
import cep from "./cep";
import company from "./company";
import birthday from "./birthday";
import eula from "./eula";

const error = createError();
let btn;
let loading;

function onLoading(isLoading) {
  btn.hidden = isLoading;
  loading.hidden = !isLoading;
  error.hidden = true;
}

function onError() {
  error.hidden = false;
}

function form(handleSubmit) {
  // svg must be created in the tree, otherwise animation will fail
  loading = e("span", { hidden: true }, [createLoading()]);

  // depends on config
  btn = createSubmit();

  const form = e("form", { class: styles.content }, [
    name(),
    cpf(),
    birthday(),
    gender(),
    phone(),
    cep(),
    company(),
    div({ class: styles.row }, [eula()]),
    div({ class: styles.row }, [error, btn, loading]),
  ]);
  form.addEventListener("submit", handleSubmit, false);
  return div({ hidden: true }, [
    t("Precisamos de algumas informações para continuar o seu atendimento."),
    form,
  ]);
}

export default {
  form,
  onError,
  onLoading,
};
