import { e, div, t } from "../tags";

import styles from "./index.module.css";
import btnStyles from "../form/elements/button.module.css";
import { font } from "../font.module.css";

import firebase from "../../firebase";

const resend = e(
  "button",
  {
    class: `${btnStyles.btn} ${font}`,
  },
  [t("Reenviar email")]
);

async function sendEmail() {
  resend.disabled = true;
  await firebase.sendEmailVerification();
  resend.hidden = true;
}

export default function create() {
  resend.addEventListener("click", sendEmail);
  return div({ hidden: true }, [
    e("p", {}, [
      t("Enviamos um e-mail com um link de confirmação para você."),
      e("br"),
      t(
        "Clique no link e acesse o site com seu e-mail e senha para finalizar o seu cadastro."
      ),
    ]),
    div(
      {
        class: `${styles.row}`,
      },
      [resend]
    ),
  ]);
}
