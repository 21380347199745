import { e, t } from "../../tags";
import config from "../../../config";

import { font } from "../../font.module.css";
import styles from "./button.module.css";

function button() {
  return e(
    "button",
    {
      type: "submit",
      style: config.get("fields.submit.style") || "",
      class: `${styles.btn} ${styles.btnPrimary} ${font}`,
    },
    [t("Continuar")]
  );
}

export default button;
