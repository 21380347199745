// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* xs */\n@media screen {\n  ._1MvP1JiLR6qs9BQzccXDww {\n    flex-direction: column;\n    align-items: flex-start !important;\n  }\n}\n\n/* md */\n@media screen and (min-width: 768px) {\n  ._1MvP1JiLR6qs9BQzccXDww {\n    flex-direction: row;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"div": "_1MvP1JiLR6qs9BQzccXDww"
};
module.exports = exports;
