import { parsePhoneNumberFromString } from "libphonenumber-js/core";
import phoneMetadata from "./elements/phone.metadata.json";

import { onSubmit } from "./onSubmit";
import { normalize } from "./cpf";
import elements from "./elements";

function formToData(form) {
  const properties = [
    "name",
    "surname",
    "cpf",
    "genderType",
    "company",
    "phone",
    "cep",
    "birthday",
    "eula",
  ];
  const values = properties.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: form[curr] ? form[curr].value : undefined,
    }),
    {}
  );

  form.reset();

  return {
    ...values,
    name: `${values.name} ${values.surname}`,
    first_name: values.name,
    cpf: normalize(values.cpf),
    cep: normalize(values.cep),
    eula: !!values.eula,
    phone: parsePhoneNumberFromString(values.phone, "BR", phoneMetadata).format(
      "E.164"
    ),
  };
}

async function handleSubmit(event) {
  event.preventDefault();
  event.stopPropagation();

  const form = event.currentTarget;
  if (!form.checkValidity()) {
    return false;
  }

  elements.onLoading(true);
  const data = formToData(form);

  try {
    await onSubmit(data);
  } catch (e) {
    elements.onLoading(false);
    elements.onError(e);
  }
}

export function onClose() {
  elements.onLoading(false);
}

export default function create() {
  return elements.form(handleSubmit);
}
