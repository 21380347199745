import firebase from "firebase/app";
import "firebase/auth";

import * as firebaseui from "./firebaseui/npm__pt_br";
import "./firebaseui/firebaseui.css";

import config from "./config";
import errorHandler from "./errorHandler";

let ui;
let currentUser;

async function init() {
  const firebaseConfig = config.get("firebase");
  firebase.initializeApp(firebaseConfig);

  // force logoff when refreshing/closing page
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

  firebase.auth().onAuthStateChanged(async function (user) {
    currentUser = user;
    errorHandler.setUser(
      (await getFhirId()) || currentUser ? currentUser.uid : undefined
    );
  });

  ui = new firebaseui.auth.AuthUI(firebase.auth());
}

function start(id, onSuccess) {
  ui.start(`#${id}`, {
    callbacks: {
      signInSuccessWithAuthResult(authResult) {
        currentUser = authResult.user;

        const validate = async () => {
          if (authResult.additionalUserInfo.isNewUser) {
            await sendEmailVerification(currentUser);
          }
          onSuccess();
        };

        // this firebaseui callback must be synchronous, otherwise
        // we get weird things with redirects. ignore this promise
        // since we don't depend on the result
        validate();

        // avoid url forwarding
        return false;
      },
    },
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ],
    tosUrl: "https://bot.3778.care/eula.html",
    privacyPolicyUrl: "https://bot.3778.care/eula.html",
    // disables accountchooser.com
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  });
}

function user() {
  return currentUser;
}

async function sendEmailVerification(usr = user()) {
  const url = new URL(window.location.href);
  url.searchParams.set("e778shown", "true");
  await usr.sendEmailVerification({
    url: url.toString(),
  });
}

async function getHasuraClaims() {
  if (!currentUser) {
    return [];
  }
  await currentUser.reload();
  const tokenResults = await currentUser.getIdTokenResult();
  return tokenResults.claims["https://hasura.io/jwt/claims"] || [];
}

async function getFhirId() {
  const claims = await getHasuraClaims();
  return claims["x-hasura-user-id"];
}

async function isRegisteredInDb() {
  // if we can get the DB ID from the token, custom
  // claims was already fired after user registered
  return !!(await getFhirId());
}

function getEmail() {
  return currentUser && currentUser.email;
}

export default {
  getEmail,
  getFhirId,
  init,
  isRegisteredInDb,
  sendEmailVerification,
  start,
  user,
};
