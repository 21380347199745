import { div, input, label, span, t } from "../../tags";
import { font } from "../../font.module.css";
import styles from "./gender.module.css";

function gender() {
  return div({ class: `${font} ${styles.div}` }, [
    label({}, [t("Qual seu sexo ao nascimento? *")]),
    span({}, [
      input({
        id: "gender_female",
        type: "radio",
        name: "genderType",
        value: "female",
        required: "true",
      }),
      label({ for: "gender_female" }, [t("Feminino")]),
    ]),
    span({}, [
      input({
        id: "gender_male",
        type: "radio",
        name: "genderType",
        value: "male",
        required: "true",
      }),
      label({ for: "gender_male" }, [t("Masculino")]),
    ]),
  ]);
}

export default gender;
