import config from "../config";

/**
 * Creates an element
 * @param tag
 * @param attributes
 * @param children
 */
export function e(tag, attributes = {}, children = []) {
  const element = document.createElement(tag);
  Object.entries(attributes).forEach(([key, value]) =>
    element.setAttribute(key, value)
  );
  children.forEach((child) => element.appendChild(child));
  return element;
}

export const div = e.bind(null, "div");
export const input = e.bind(null, "input");
export const label = e.bind(null, "label");
export const span = e.bind(null, "span");

/**
 * Creates a text node
 * @param text
 */
export function t(text) {
  return document.createTextNode(text);
}

export function logo() {
  return e("img", {
    src: config.get("logo"),
  });
}
