import validarCpf from "validar-cpf";

import { div, e, t } from "../../tags";
import { font } from "../../font.module.css";

function validator(input) {
  const cpf = input.value;
  if (!validarCpf(cpf)) {
    input.setCustomValidity("O CPF parece ser inválido");
  } else {
    input.setCustomValidity("");
  }
}

// avoids escaping [.-] because of https://bugzilla.mozilla.org/show_bug.cgi?id=1227906
const pattern = "\\d{3}[.]?\\d{3}[.]?\\d{3}[-]?\\d{2}";

function row() {
  const input = e("input", {
    type: "text",
    required: "true",
    placeholder: "CPF",
    name: "cpf",
    class: font,
    minlength: "11",
    maxlength: "14",
    inputmode: "decimal",
    pattern,
  });

  input.addEventListener("input", validator.bind(null, input), false);

  return div({}, [e("label", { for: "cpf" }, [t("Qual seu CPF? *")]), input]);
}

export default row;
